import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { Colors } from '../../colors';

@Component({
    selector: 'crew-ui-icon',
    imports: [CommonModule],
    templateUrl: './crew-ui-icon.component.html',
    styleUrls: ['./crew-ui-icon.component.css']
})
export class CrewUIIconComponent {
  name = input.required<string>();

  color = input<Colors>();

  size = input<string>('2xl');

  weight = input('normal');

  leading = input<string>();

  get buildTheme(): string[] {
    const base = ['material-symbols-outlined', 'text-center', 'align-middle'];
    if (this.color()) base.push(`text-${this.color()}`);
    if (this.leading()) base.push(`leading-${this.leading()}`);
    base.push(`text-${this.size()}`);
    base.push(`font-${this.weight()}`);
    return base;
  }
}

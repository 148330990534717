import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Colors } from '../../colors';
import { CrewUIDarkModeService } from '../../services/darkmode.service';
import { CrewUIIconComponent } from '../crew-ui-icon/crew-ui-icon.component';

@Component({
    selector: 'crew-ui-search-bar',
    imports: [CommonModule, CrewUIIconComponent, FormsModule],
    templateUrl: './crew-ui-search-bar.component.html',
    styleUrls: ['./crew-ui-search-bar.component.css']
})
export class CrewUISearchBarComponent implements OnInit {
  @Input()
  placeholder?: string = 'Search...';

  @Input()
  value = '';

  @Output()
  valueChange = new EventEmitter<string>();

  iconColor?: Colors;

  searchIconColor?: Colors = Colors.Nickel;

  constructor(private darkModeService: CrewUIDarkModeService) {}

  ngOnInit(): void {
    this.iconColor = this.darkModeService.isDarkMode()
      ? Colors.White
      : Colors.Carbon;
  }

  onCancel(): void {
    this.value = '';
    this.valueChange.emit(this.value);
  }

  onFocus(): void {
    this.iconColor = this.darkModeService.isDarkMode()
      ? Colors.White
      : Colors.Carbon;
    this.searchIconColor = this.darkModeService.isDarkMode()
      ? Colors.White
      : Colors.Carbon;
  }

  emit($event: string) {
    this.valueChange.emit($event);
  }
}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
  CrewUISearchBarComponent,
} from '../../../shared/components/crew-ui';
import { FullMessageData } from '../../../shared/services/azure/message.service.types';
import { MessageIdbService } from '../../../shared/services/idb/message.idb.service';
import { TimeService } from '../../../shared/services/time.service';
import { MessageService } from '../message.service';
@Component({
    selector: 'crew-web-message-short-view',
    templateUrl: './message-short-view.component.html',
    styleUrls: ['./message-short-view.component.css'],
    imports: [
        CommonModule,
        CrewUIIconComponent,
        CrewUISearchBarComponent,
        ScrollingModule,
    ]
})
export class MessageShortViewComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private messagesIdbService: MessageIdbService,
    private messageService: MessageService,
    private timeService: TimeService,
  ) {}

  get selectedId(): number | undefined {
    if (this.messageService.selectedMessage()?.messageId)
      return this.messageService.selectedMessage()?.messageId;
    else return -1;
  }

  colors = Colors;

  showSearchBar = false;

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get messages(): FullMessageData[] | undefined {
    return this.messageService.getSelectedMenuMessages();
  }

  get title(): string | undefined {
    return this.messageService.selectedTitle();
  }

  get hasMessages(): boolean {
    const messages = this.messageService.getSelectedMenuMessages();
    return messages && messages?.length > 0 ? true : false;
  }

  search(input: string): void {
    if (input === '') this.messageService.clearSearch();
    else this.messageService.search(input);
  }

  toggleSearch(): void {
    this.showSearchBar = !this.showSearchBar;
    if (!this.showSearchBar) {
      this.messageService.clearSearch();
    }
  }

  goBack(event: Event): void {
    event.stopPropagation();
    this.messageService.selectedMenu.set(undefined);
    this.messageService.selectedMessage.set(undefined);
    this.messageService.clearSearch();
  }

  onSelectMessage(message: FullMessageData): void {
    if (!message.isHi6Message) {
      this.messagesIdbService.markAsRead(message).subscribe();
      this.messagesIdbService.populateMessageBody(message);
    } else if (message.isHi6Message) {
      this.messageService.setSelectedMessage(message);
    }
  }

  showDateTime(value: string): string {
    return this.timeService.getMessageDateTimeShort(value);
  }
}

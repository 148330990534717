import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'crew-ui-divider',
    imports: [CommonModule],
    templateUrl: './crew-ui-divider.component.html',
    styleUrls: ['./crew-ui-divider.component.css']
})
export class CrewUIDividerComponent {
  @Input()
  text = '';

  @Input()
  kind: 'primary' | 'secondary' | 'tertiary' = 'primary';

  @Input()
  vertical = false;

  backgroundTheme(): object {
    return {
      'bg-cirrus dark:bg-carbon': this.kind === 'primary',
      'bg-turbine dark:bg-steel': this.kind === 'secondary',
      'bg-aluminum dark:bg-nickel': this.kind === 'tertiary',
    };
  }
}

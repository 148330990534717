import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CrewUIToastService } from '../../services/crew-ui-toast.service';
import { CrewUIIconComponent } from '../crew-ui-icon/crew-ui-icon.component';
import { CrewUIToastOptions } from './crew-ui-toast.types';

@Component({
    selector: 'crew-ui-toast',
    imports: [CommonModule, CrewUIIconComponent],
    templateUrl: './crew-ui-toast.component.html',
    styleUrls: ['./crew-ui-toast.component.css'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0,
            })),
            transition('void <=> *', animate(300)),
        ]),
    ]
})
export class CrewUIToastComponent {
  constructor(private toastService: CrewUIToastService) {}

  get toastServiceOptions(): CrewUIToastOptions {
    return this.toastService.options();
  }

  get isVisible(): boolean {
    return this.toastService.isVisible();
  }

  close(): void {
    this.toastService.hideToast();
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'crew-ui-logo',
    imports: [CommonModule],
    templateUrl: './crew-ui-logo.component.html',
    styleUrls: ['./crew-ui-logo.component.css']
})
export class CrewUILogoComponent {
  @Input()
  text?: string;

  @Input()
  full = true;
}

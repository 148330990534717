import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { InsightsService } from '../../shared/services/azure/insights.service';
import {
  FullMessageData,
  MessagePriority,
} from '../../shared/services/azure/message.service.types';
import { BreakpointService } from '../../shared/services/breakpoint.service';
import { HttpService } from '../../shared/services/http.service';
import { UserService } from '../../shared/services/user.service';
import { MessageService } from './message.service';
import { MessageDetailViewComponent } from './message-detail-view/message-detail-view.component';
import { MessageMenuComponent } from './message-menu/message-menu.component';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { MessageShortViewComponent } from './message-short-view/message-short-view.component';
@Component({
    selector: 'crew-web-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css'],
    imports: [
        CommonModule,
        MessageMenuComponent,
        MessageShortViewComponent,
        MessageDetailViewComponent,
        MessageModalComponent,
    ]
})
export class MessageComponent implements OnInit {
  constructor(
    private breakpointService: BreakpointService,
    private httpService: HttpService,
    private insightsService: InsightsService,
    private messageService: MessageService,
    private userService: UserService,
  ) {}
  ngOnInit(): void {
    this.insightsService.trackPageView({
      name: 'Message',
    });
    this.getHi6();
  }

  messagePriorities = MessagePriority;

  selectedMenuDetailedMessage: FullMessageData = {} as FullMessageData;

  get isLoading() {
    return this.httpService.isLoading();
  }

  get selectedPriority() {
    return this.messageService.selectedMenu();
  }

  get selectedMessageAndBody() {
    return (
      this.messageService.selectedMessage() &&
      this.messageService.selectedMessage()?.body
    );
  }

  getHi6() {
    // this.messageService.fetchHi6Count();
    this.messageService.fetchHi6Get();
  }

  buildMenuViewClasses() {
    return {
      hidden:
        (this.selectedMessageAndBody ||
          (this.selectedPriority &&
            this.selectedPriority === this.messagePriorities.HI6)) &&
        this.breakpointService.isXs,
      'w-full':
        (!this.selectedMessageAndBody ||
          (this.selectedPriority &&
            this.selectedPriority !== this.messagePriorities.HI6)) &&
        this.breakpointService.isXs,
      'w-1/3': this.breakpointService.isGtXs,
      'w-1/4': this.breakpointService.isGtMd,
    };
  }

  buildDetailViewClasses() {
    return {
      'w-full':
        (this.selectedMessageAndBody ||
          (this.selectedPriority &&
            this.selectedPriority === this.messagePriorities.HI6)) &&
        this.breakpointService.isXs,
      'w-2/3': this.breakpointService.isGtXs,
      'w-3/4': this.breakpointService.isGtMd,
    };
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'crew-ui-card',
    imports: [CommonModule],
    templateUrl: './crew-ui-card.component.html',
    styleUrls: ['./crew-ui-card.component.css']
})
export class CrewUICardComponent {}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TripSignInAlert } from '../../types';
import { CrewUIOverlayComponent } from '../crew-ui';
@Component({
    selector: 'crew-web-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.css'],
    imports: [CommonModule, CrewUIOverlayComponent]
})
export class AlertModalComponent {
  @Input() alertMessage = {} as TripSignInAlert;

  @Output() clickClose: EventEmitter<Event> = new EventEmitter();

  closeAlert(event: Event) {
    this.clickClose.emit(event);
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';

import {
  CommonResults,
  GetSabreCommandsRequest,
} from '../../services/azure/sabre.service.types';
import { Events } from '../../services/azure/schedule.service.types';
import { TimeService } from '../../services/time.service';
import { GridHeightOperationType } from '../../types';
import { TripSignInAlert } from '../../types';
import { formatDate } from '../../utils/date.utils';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
  CrewUIOverlayComponent,
} from '../crew-ui';
import { ForwardMessageComponent } from '../forwardMessage/forward-message.component';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { SafeHtmlComponent } from '../safe-html/safe-html.component';
import { SabreDocumentService } from './sabre-document.service';
@Component({
    selector: 'crew-web-sabre-document',
    templateUrl: './sabre-document.component.html',
    styleUrls: ['./sabre-document.component.css'],
    imports: [
        AlertModalComponent,
        CommonModule,
        CrewUIIconComponent,
        CrewUIOverlayComponent,
        ForwardMessageComponent,
        PdfViewerComponent,
        SafeHtmlComponent,
    ]
})
export class SabreDocumentComponent {
  @Output() clickClose: EventEmitter<boolean> = new EventEmitter();

  @Output() clickFlightPlanButton: EventEmitter<Events> = new EventEmitter();

  shareMenu = false;

  isForwardComponentVisible = false;

  colors = Colors;

  constructor(
    private timeService: TimeService,
    private sabreDocumentService: SabreDocumentService,
    private darkModeService: CrewUIDarkModeService,
  ) {}

  readonly gridHeightOperationType = GridHeightOperationType;

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get sabreResponse(): CommonResults | undefined {
    return this.sabreDocumentService.sabreResponse();
  }

  get signInFlightPlanEvent(): Events {
    return this.sabreDocumentService.signInFlightPlanEvent();
  }

  /**
   * Toggle trip sign alert
   */
  get tripSignInAlert(): TripSignInAlert {
    return this.sabreDocumentService.tripSignInAlert();
  }

  set clearTripSignInAlertMessage(event: Event) {
    this.sabreDocumentService.clearTripSignInAlertMessage(event);
  }

  callForwardMessage(): void {
    this.isForwardComponentVisible = !this.isForwardComponentVisible;
  }

  getEFlightPlanPdfFileName(
    payload: GetSabreCommandsRequest | undefined,
  ): string {
    return this.sabreDocumentService.getEFlightPlanPdfFileName(payload);
  }

  onClickClose(event: Event): void {
    this.clearTripSignInAlertMessage = event;
  }

  signFlightPlan(event: Events, type = ''): boolean | string {
    return this.sabreDocumentService.viewOrSignInFlightPlan(event, type);
  }

  showUpdatedTime(date: string | undefined): string {
    let response = ``;
    if (date) {
      response = `Updated ${formatDate(
        date,
        'MM/DD/YYYY',
      )} at ${this.timeService.getTime(date)}`;
    }
    return response;
  }

  onCloseDocument(): void {
    this.sabreDocumentService.clearSabreResponse();
    this.clickClose.emit(true);
  }

  onRefresh(response = {} as CommonResults): void {
    this.sabreDocumentService.reloadSabreCall(response);
  }

  onClickSignFlightButton(sequenceEvent: Events): void {
    this.clickFlightPlanButton.emit(sequenceEvent);
  }

  onShareDocument(event: Event): void {
    event.stopPropagation();
    this.shareMenu = !this.shareMenu;
  }
}

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  Colors,
  CrewUICardComponent,
  CrewUIDarkModeService,
  CrewUIDividerComponent,
  CrewUIIconComponent,
  CrewUIOverlayComponent,
  CrewUIToastOptions,
  CrewUIToastService,
} from '../../../shared/components/crew-ui';
import { DialogConfirmModalComponent } from '../../../shared/components/dialog-confirm-modal/dialog-confirm-modal.component';
import { SabreDocumentKeywordDisplay } from '../../../shared/components/sabre-document/sabre-document.constant';
import { SafeHtmlComponent } from '../../../shared/components/safe-html/safe-html.component';
import { SafeHtmlPipe } from '../../../shared/pipes/safeHtml.pipe';
import { CoreService } from '../../../shared/services/azure/core.service';
import { SendFeedbackEmailV2Request } from '../../../shared/services/azure/core.service.types';
import {
  FullMessageData,
  MessagePriority,
} from '../../../shared/services/azure/message.service.types';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
import { MessageIdbService } from '../../../shared/services/idb/message.idb.service';
import { TimeService } from '../../../shared/services/time.service';
import { UserService } from '../../../shared/services/user.service';
import { BusinessUnit } from '../../../shared/types';
import { MessageService } from '../message.service';
@Component({
    selector: 'crew-web-message-detail-view',
    templateUrl: './message-detail-view.component.html',
    styleUrls: ['./message-detail-view.component.css'],
    imports: [
        CommonModule,
        CrewUIIconComponent,
        CrewUIDividerComponent,
        SafeHtmlPipe,
        SafeHtmlComponent,
        CrewUIOverlayComponent,
        CrewUICardComponent,
        ReactiveFormsModule,
        DialogConfirmModalComponent,
    ]
})
export class MessageDetailViewComponent implements AfterViewInit {
  constructor(
    private breakpointService: BreakpointService,
    private coreService: CoreService,
    private darkModeService: CrewUIDarkModeService,
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private messageIdbService: MessageIdbService,
    private messageService: MessageService,
    private messagesIdbService: MessageIdbService,
    private renderer: Renderer2,
    private timeService: TimeService,
    private toastService: CrewUIToastService,
    private userService: UserService,
  ) {
    this.forwardByEmailForm = this.formBuilder.group({
      to: ['', [Validators.email, Validators.required]],
      cc: ['', [Validators.email]],
      subject: [this.message?.messageTitle, [Validators.required]],
      body: [this.bodyPlaceholder, [Validators.required]],
    });

    this.toSignal = toSignal(this.forwardByEmailForm.get('to')!.valueChanges, {
      initialValue: '',
    });

    this.ccSignal = toSignal(this.forwardByEmailForm.get('cc')!.valueChanges, {
      initialValue: '',
    });

    this.subjectSignal = toSignal(
      this.forwardByEmailForm.get('subject')!.valueChanges,
      {
        initialValue: this.message?.messageTitle,
      },
    );

    this.bodySignal = toSignal(
      this.forwardByEmailForm.get('body')!.valueChanges,
      {
        initialValue: this.bodyPlaceholder,
      },
    );
  }

  showDeleteButton(): boolean {
    let result = false;
    if (
      this.businessUnit === this.businessUnits.AAPI ||
      this.businessUnit === this.businessUnits.MQPI
    ) {
      result =
        this.selectedMenu !== this.menus.Saved &&
        this.selectedMenu !== this.messagePriority.HI6 &&
        this.message?.priorityNumber !==
          this.messagePriority.ReadAndAcknowledge &&
        !this.message?.messageDeleted;
    } else {
      result =
        this.selectedMenu !== this.menus.Saved &&
        this.selectedMenu !== this.messagePriority.HI6 &&
        !this.message?.messageDeleted;
    }
    return result;
  }
  isSavedMessage(): boolean {
    const savedMessages = this.messageService.savedMessages();

    if (!savedMessages) return false;

    const saved = savedMessages.find(
      (m) => m.messageId === this.message?.messageId,
    );

    if (saved) return true;

    return false;
  }

  get isGtSm(): boolean {
    return this.breakpointService.isGtSm;
  }
  get isXs(): boolean {
    return this.breakpointService.isXs;
  }

  goBack(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.selectedMenu === this.messagePriority.HI6) {
      this.messageService.selectedMenu.set(undefined);
    } else {
      this.messageService.selectedMessage.set(undefined);
    }
  }

  readonly bodyPlaceholder = 'Enter additional comments here.';

  menus = MessagePriority;

  forwardByEmailForm: FormGroup;

  toSignal: Signal<string>;

  ccSignal: Signal<string>;

  subjectSignal: Signal<string>;

  bodySignal: Signal<string>;

  isSending = false;

  get isSendable(): boolean {
    return this.forwardByEmailForm.valid && !this.isSending;
  }

  colors = Colors;

  messagePriority = MessagePriority;

  businessUnits = BusinessUnit;

  isFullscreen = false;

  isDropdownVisible = false;

  isForwardByEmailModalVisible = false;

  fontSize = 16;

  readonly maxFontSize = this.fontSize * 2;

  readonly minFontSize = this.fontSize;

  showHi6NextButton = false;

  get businessUnit(): BusinessUnit | undefined {
    return this.userService.emulatedOrDefaultBusinessUnit();
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get message(): FullMessageData | undefined {
    return this.messageService.selectedMessage();
  }

  get hi6Message(): string {
    return this.messageService.hi6Message();
  }

  get hi6Count(): number {
    return this.messageService.hi6Count();
  }

  get increaseFontSizeDisabled(): boolean {
    return this.fontSize >= this.maxFontSize;
  }

  get decreaseFontSizeDisabled(): boolean {
    return this.fontSize <= this.minFontSize;
  }

  get hasNextMessage(): boolean {
    return this.messageService.hasNextMessage();
  }

  get hasPreviousMessage(): boolean {
    return this.messageService.hasPreviousMessage();
  }

  get nextMessageIconColor(): Colors {
    if (
      this.hasNextMessage ||
      (this.selectedMenu === MessagePriority.HI6 && this.hi6Count > 0)
    ) {
      return this.isDark ? Colors.Troposphere : Colors.Stratosphere;
    } else {
      return this.isDark ? Colors.Steel : Colors.Aluminum;
    }
  }

  get previousMessageIconColor(): Colors {
    if (this.hasPreviousMessage)
      return this.isDark ? Colors.Troposphere : Colors.Stratosphere;
    else return this.isDark ? Colors.Steel : Colors.Aluminum;
  }

  get selectedMenu(): MessagePriority | undefined {
    return this.messageService.selectedMenu();
  }

  // TODO: Possibly remove this if it's not needed in future versions of Angular.
  // * This is required for IOS Safari support in Angular 16. Sometimes the buttons just don't work.
  ngAfterViewInit(): void {
    const nextButton = this.el.nativeElement.querySelector('#next-button');
    const prevButton = this.el.nativeElement.querySelector('#previous-button');
    const backButton = this.el.nativeElement.querySelector('#back-button');
    const fullscreenButton =
      this.el.nativeElement.querySelector('#fullscreen-button');
    const deleteButton = this.el.nativeElement.querySelector('#delete-button');
    const restoreButton =
      this.el.nativeElement.querySelector('#restore-button');
    const saveButton = this.el.nativeElement.querySelector('#save-button');
    const moreButton = this.el.nativeElement.querySelector('#more-button');

    if (nextButton) {
      this.renderer.listen(nextButton, 'touchstart', (event) => {
        this.nextMessage(event);
      });
    }

    if (prevButton) {
      this.renderer.listen(prevButton, 'touchstart', (event) => {
        this.previousMessage(event);
      });
    }

    if (backButton) {
      this.renderer.listen(backButton, 'touchstart', (event) => {
        this.goBack(event);
      });
    }

    if (fullscreenButton) {
      this.renderer.listen(fullscreenButton, 'touchstart', (event) => {
        this.toggleFullscreen(event);
      });
    }

    if (deleteButton) {
      this.renderer.listen(deleteButton, 'touchstart', (event) => {
        this.deleteMessage(event);
      });
    }

    if (restoreButton) {
      this.renderer.listen(restoreButton, 'touchstart', (event) => {
        this.restoreDeletedMessage(event);
      });
    }

    if (saveButton) {
      this.renderer.listen(saveButton, 'touchstart', (event) => {
        this.toggleSaveMessage(event);
      });
    }

    if (moreButton) {
      this.renderer.listen(moreButton, 'touchstart', (event) => {
        this.toggleDropdownMenu(event);
      });
    }
  }

  buildFontSize(): object {
    return {
      'font-size': `${this.fontSize}px`,
    };
  }

  isMoreIconVisible(): boolean | undefined {
    return (
      this.selectedMenu !== this.messagePriority.HI6 &&
      this.message &&
      !this.message?.isHi6Message
    );
  }

  showTitle(message = {} as FullMessageData): string {
    let title = ``;
    if (message?.messageTitle) {
      title = message?.messageTitle;
    }
    if (
      this.selectedMenu === MessagePriority.HI6 ||
      (this.selectedMenu === MessagePriority.Saved && message?.isHi6Message)
    ) {
      title = `HI6 Message`;
    }
    return title;
  }

  decreaseFontSize(event: Event): void {
    event.stopPropagation();

    if (this.decreaseFontSizeDisabled) return;

    this.fontSize -= 2;
  }

  increaseFontSize(event: Event): void {
    event.stopPropagation();

    if (this.increaseFontSizeDisabled) return;

    this.fontSize += 2;
  }

  showDateTime(date?: string): string {
    return this.timeService.getMessageDateTimeLong(date);
  }

  toggleFullscreen(event: Event): void {
    event.stopPropagation();
    this.isFullscreen = !this.isFullscreen;
  }

  toggleForwardByEmailModal(event: Event): void {
    this.toggleDropdownMenu(event);
    this.isForwardByEmailModalVisible = !this.isForwardByEmailModalVisible;
  }

  toggleDropdownMenu(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  getNextHi6Message(event: boolean): void {
    if (event) {
      this.showHi6NextButton = false;
      this.messageService.fetchHi6Get(true);
    }
  }

  nextMessage(event: Event): void {
    event.stopPropagation();
    if (this.selectedMenu !== MessagePriority.HI6 && this.hasNextMessage) {
      this.setNextSelectedMessage();
      return;
    }
    if (this.selectedMenu === MessagePriority.HI6 && this.hi6Count > 0) {
      this.showHi6NextButton = !this.showHi6NextButton;
      return;
    }
  }

  previousMessage(event: Event): void {
    event.stopPropagation();

    if (!this.hasPreviousMessage) return;

    this.setPreviousSelectedMessage();
  }

  setNextSelectedMessage(): void {
    const selected = this.messageService.selectedMessage();
    const messages = this.messageService.getSelectedMenuMessages();
    if (!selected || !messages) return;
    const i = messages.findIndex((s) => selected.messageId === s.messageId) + 1;
    const message = messages[i];
    if (message.messageTab !== SabreDocumentKeywordDisplay.HI6GET) {
      this.messageIdbService.markAsRead(message).subscribe();
      this.messageIdbService.populateMessageBody(message);
    } else {
      this.messageService.setSelectedMessage(message);
    }
  }

  setPreviousSelectedMessage(): void {
    const selected = this.messageService.selectedMessage();
    const messages = this.messageService.getSelectedMenuMessages();
    if (!selected || !messages) return;
    const i = messages.findIndex((s) => selected.messageId === s.messageId) - 1;
    const message = messages[i];
    if (message.messageTab !== SabreDocumentKeywordDisplay.HI6GET) {
      this.messageIdbService.markAsRead(message).subscribe();
      this.messageIdbService.populateMessageBody(message);
    } else {
      this.messageService.setSelectedMessage(message);
    }
  }

  forwardByEmail(event: Event): void {
    event.stopPropagation();

    this.isSending = true;

    const successToast: CrewUIToastOptions = {
      message: 'Your message was successfully sent.',
      backgroundColor: Colors.Zanzibar,
      textColor: Colors.White,
    };

    const failureToast: CrewUIToastOptions = {
      message: 'Could not send message. Please try again.',
      backgroundColor: Colors.Afterburner,
      textColor: Colors.White,
    };

    const additionalComments = this.bodySignal().replace(
      this.bodyPlaceholder,
      '',
    );

    const bodySpacer =
      this.bodySignal() === this.bodyPlaceholder ? '' : '<br/><br/>';

    const endSpacer = '<br/><br/>';

    const payload: SendFeedbackEmailV2Request = {
      employeeNumber: this.userService.emulatedOrDefaultEmployeeNumber(),
      buCode: this.userService.emulatedOrDefaultBusinessUnit(),
      toRecepients: this.toSignal().split(';'),
      ccRecepients: this.ccSignal()
        .split(';')
        .filter((c) => c !== ''),
      subject: this.subjectSignal(),
      emailBody:
        additionalComments + bodySpacer + this.message?.body + endSpacer,
      osVersion: '',
      deviceOS: '',
      appVersion: '',
      isFeedback: false,
      feedback: false,
    };

    this.coreService.sendFeedbackEmailV2(payload).subscribe((x) => {
      if (x.success) {
        this.toggleForwardByEmailModal(event);
        this.toggleDropdownMenu(event);
        this.isSending = false;
        this.toastService.showToast(successToast);
      } else this.toastService.showToast(failureToast);
    });
  }

  restoreDeletedMessage(event: Event): void {
    event.stopPropagation();

    const successToast: CrewUIToastOptions = {
      message: 'Your message was successfully restored.',
      backgroundColor: Colors.Zanzibar,
      textColor: Colors.White,
    };

    const failureToast: CrewUIToastOptions = {
      message: 'Could restore not deleted message. Please try again.',
      backgroundColor: Colors.Afterburner,
      textColor: Colors.White,
    };

    this.messagesIdbService.restoreDeletedSelectedMessage().subscribe((x) => {
      if (x.success) this.toastService.showToast(successToast);
      else this.toastService.showToast(failureToast);
    });
  }

  deleteMessage(event: Event): void {
    event.stopPropagation();

    const successToast: CrewUIToastOptions = {
      message: 'Your message was successfully deleted.',
      backgroundColor: Colors.Zanzibar,
      textColor: Colors.White,
    };

    const failureToast: CrewUIToastOptions = {
      message: 'Could not delete message. Please try again.',
      backgroundColor: Colors.Afterburner,
      textColor: Colors.White,
    };

    this.messagesIdbService.deleteSelectedMessage().subscribe((x) => {
      if (x.success) this.toastService.showToast(successToast);
      else this.toastService.showToast(failureToast);
    });
  }

  async saveMessage(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    if (!this.message) return;

    if (!this.isSavedMessage()) {
      try {
        await this.messagesIdbService.createSavedMessage(this.message);
      } catch (error) {
        console.error('Could not create saved message.');
        console.error(error);
      }
    } else {
      try {
        this.messagesIdbService.deleteSavedMessage(this.message.messageId);
      } catch (error) {
        console.error('Could not delete saved message.');
        console.error(error);
      }

      // * if the user is on the saved menu the message needs to unselected.
      // * if the user is on any other menu it doesn't matter
      if (this.selectedMenu && this.selectedMenu === MessagePriority.Saved) {
        this.messageService.selectedMessage.set(undefined);
      }
    }

    try {
      this.messageService.savedMessages.set(
        await this.messagesIdbService.getAllSavedMessages(),
      );
    } catch (error) {
      console.error(error);
    }
  }

  checkHi6MessageExists(): boolean {
    const savedMessages = this.messageService.savedMessages();
    if (savedMessages) {
      const saved = savedMessages.find((m) => m.body === this.hi6Message);
      if (saved) return true;
    }
    return false;
  }

  async toggleSaveMessage(event: MouseEvent): Promise<void> {
    event.stopPropagation();
    if (this.message || this.selectedMenu === MessagePriority.HI6) {
      if (!this.isSavedMessage()) {
        try {
          if (
            this.selectedMenu === MessagePriority.HI6 &&
            !this.checkHi6MessageExists()
          ) {
            let message = {} as FullMessageData;
            message = this.messageService.hi6MessageTemplate(this.hi6Message);
            await this.messagesIdbService.createSavedMessage(message);
          } else if (this.message) {
            await this.messagesIdbService.createSavedMessage(this.message);
          }
        } catch (error) {
          console.error('Could not create saved message.');
          console.error(error);
        }
      } else {
        try {
          if (this.message) {
            this.messagesIdbService.deleteSavedMessage(this.message.messageId);
          }
        } catch (error) {
          console.error('Could not delete saved message.');
          console.error(error);
        }

        // * if the user is on the saved menu the message needs to unselected.
        // * if the user is on any other menu it doesn't matter
        if (this.selectedMenu && this.selectedMenu === MessagePriority.Saved) {
          this.messageService.selectedMessage.set(undefined);
        }
      }

      try {
        this.messageService.savedMessages.set(
          await this.messagesIdbService.getAllSavedMessages(),
        );
      } catch (error) {
        console.error(error);
      }
    }
  }
}

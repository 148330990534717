import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import {
  Colors,
  CrewUICardComponent,
  CrewUIDarkModeService,
} from '../../../shared/components/crew-ui';
import { Events } from '../../../shared/services/azure/schedule.service.types';
import { TimeService } from '../../../shared/services/time.service';
import { UserService } from '../../../shared/services/user.service';
import { BusinessUnit } from '../../../shared/types';
import { getTodayStartEndDateTimeInBetweenSequenceDates } from '../../../shared/utils/date.utils';

@Component({
    selector: 'crew-web-overview-non-sequence-card',
    templateUrl: './overview-non-sequence-card.component.html',
    styleUrls: ['./overview-non-sequence-card.component.css'],
    imports: [CommonModule, CrewUICardComponent]
})
export class OverviewNonSequenceCardComponent implements OnInit {
  constructor(
    private timeService: TimeService,
    private darkModeService: CrewUIDarkModeService,
    private userService: UserService,
  ) {}

  @Input({ required: true })
  event!: Events;

  title = '';

  color = Colors.Black;

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  ngOnInit(): void {
    if (this.event.activityText === 'D') {
      this.color = this.isDark ? Colors.Tangerine : Colors.Nectarine;
      if (this.event.dfpType === 'GD') {
        this.title = 'Golden Day';
      } else if (this.event.dfpType === 'FD') {
        this.title = 'Flex Day';
      } else if (this.event.dfpType.startsWith('D')) {
        this.title = 'Duty Free Period';
      } else {
        this.title = this.event.dfpType;
      }
      return;
    }

    if (this.event.activityText.includes('RAP')) {
      const businessUnit = this.userService.emulatedOrDefaultBusinessUnit();
      const isPilot = businessUnit === BusinessUnit.AAPI || BusinessUnit.MQPI;
      this.title = isPilot ? 'RAP' : this.event.activityText;
      this.color = this.isDark ? Colors.Troposphere : Colors.Stratosphere;
      return;
    }

    if (this.event.activityGroupCode === 'VC') {
      this.color = Colors.Monarch;
      this.title = 'Vacation';
      return;
    }

    if (this.event.activityGroupCode === 'TR') {
      this.color = Colors.Zanzibar;
      this.title = 'Training';
      return;
    }

    if (this.event.activityCode === 'SK') {
      this.color = Colors.Afterburner;
      this.title = 'Sick';
      return;
    }

    if (this.event.activityCode === 'PR') {
      this.color = Colors.Afterburner;
      this.title = 'Proby';
      return;
    }

    if (this.event.activityText === 'A') {
      this.color = Colors.Monarch;
      this.title = 'Leave';
      return;
    }

    if (this.event.isStandby) {
      this.color = Colors.Zanzibar;
      this.title = 'Standby';
      return;
    }

    this.title = this.event.activityText;
    this.color = Colors.Afterburner;
  }

  getColorClass(): string[] {
    return [`bg-${this.color}`];
  }

  formatTime(startDate: string, endDate: string, day: string): string {
    const { startDateTime, endDateTime } =
      getTodayStartEndDateTimeInBetweenSequenceDates(startDate, endDate, day);
    return `${this.timeService.getTime(
      startDateTime,
    )} - ${this.timeService.getTime(endDateTime)}`;
  }
}

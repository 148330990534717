import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FlightTimePipe } from '../../../../shared/pipes/flightTime.pipe';
import { FlightLeg } from '../../../../shared/services/azure/calendar.service.types';
import { TimeService } from '../../../../shared/services/time.service';
import {
  getArrivalColor,
  getDepartureColor,
  showArrivalTime,
  showDepartureTime,
  validateActualRescheduledArrivalTime,
  validateActualRescheduledDepartureTime,
} from '../../../../shared/utils/flightLeg.utils';
import { EventTypeColor } from '../../calendar.types';
import { CardTextHighlightComponent } from '../card-text-highlight/card-text-highlight.component';
@Component({
    selector: 'crew-web-card-flight-info',
    templateUrl: './card-flight-info.component.html',
    styleUrls: ['./card-flight-info.component.css'],
    imports: [CommonModule, CardTextHighlightComponent, FlightTimePipe]
})
export class CardFlightInfoComponent {
  @Input() flightInfo = {} as FlightLeg;

  @Input() eventColor = {} as EventTypeColor;

  constructor(private timeService: TimeService) {}

  getTime(dateTime: string): string {
    return `${this.timeService.getTime(dateTime)}`;
  }

  getDepartureColor(flightInfo: FlightLeg): string {
    return getDepartureColor(flightInfo);
  }

  getArrivalColor(flightInfo: FlightLeg): string {
    return getArrivalColor(flightInfo);
  }

  showArrivalTime(flightInfo: FlightLeg): string {
    let arrivalTime = ``;
    if (showArrivalTime(flightInfo)) {
      arrivalTime = `${this.timeService.getTime(showArrivalTime(flightInfo))}`;
    }
    return arrivalTime;
  }

  showDepartureTime(flightInfo: FlightLeg): string {
    let departureTime = ``;
    if (showArrivalTime(flightInfo)) {
      departureTime = `${this.timeService.getTime(
        showDepartureTime(flightInfo),
      )}`;
    }
    return departureTime;
  }

  validateActualRescheduledDepartureTime(flightInfo: FlightLeg): boolean {
    return validateActualRescheduledDepartureTime(flightInfo);
  }

  validateActualRescheduledArrivalTime(flightInfo: FlightLeg): boolean {
    return validateActualRescheduledArrivalTime(flightInfo);
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { CREW_ROUTES } from '../../../app-routing.module';
import {
  Colors,
  CrewUICardComponent,
  CrewUIDarkModeService,
  CrewUIDividerComponent,
  CrewUIIconComponent,
  CrewUIToastService,
} from '../../../shared/components/crew-ui';
import { NotificationService } from '../../../shared/services/azure/notification.service';
import { SaveNotificationAckRequest } from '../../../shared/services/azure/notification.service.types';
import {
  FirebaseIdbService,
  NotificationPayload,
} from '../../../shared/services/idb/firebase.idb.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
    selector: 'crew-web-overview-notification',
    templateUrl: './overview-notification.component.html',
    styleUrls: ['./overview-notification.component.css'],
    imports: [
        CommonModule,
        CrewUICardComponent,
        CrewUIDividerComponent,
        CrewUIIconComponent,
    ]
})
export class OverviewNotificationComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private firebaseIdbService: FirebaseIdbService,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private toastService: CrewUIToastService,
  ) {}

  @Input({ required: true })
  notification!: NotificationPayload;

  @Output()
  dismiss = new EventEmitter<void>();

  get isAckRequired(): boolean {
    return this.notification?.data?.isAckRequired === 'true';
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get buttonClass(): string[] {
    let classes = [
      'mt-2',
      'flex',
      'w-full',
      'cursor-pointer',
      'items-center',
      'rounded-md',
      'bg-opacity-10',
      'p-2',
    ];

    if (this.isAckRequired)
      classes = [
        ...classes,
        'bg-afterburner',
        'text-afterburner',
        'justify-between',
      ];
    else
      classes = [
        ...classes,
        'bg-stratosphere',
        'text-stratosphere',
        'justify-center',
        'dark:bg-troposphere',
        'dark:bg-opacity-10',
        'dark:text-troposphere',
      ];

    return classes;
  }

  get buttonText(): string {
    if (this.isAckRequired) return 'Check calendar';
    return 'Dismiss';
  }

  get iconColor(): Colors {
    if (this.isAckRequired) return Colors.Afterburner;

    if (this.isDark) return Colors.Troposphere;

    return Colors.Stratosphere;
  }

  colors = Colors;

  onClick(event: MouseEvent): void {
    event.stopPropagation();

    const payload: SaveNotificationAckRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmpId: this.userService.employeeNumber(),
      deviceToken: '',
      uniqueValue: '',
      messageIds: [this.notification.messageId],
      //expected format "yyyy-MM-dd'T'HH:mm:ss'Z'"
      timeStamp: new Date().toISOString().slice(0, -5) + 'Z',
    };

    this.notificationService.saveNotificationAck(payload).subscribe((res) => {
      if (!res.success) this.showErrorToast();

      this.firebaseIdbService
        .deleteNotification(this.notification.messageId)
        .then(() => {
          this.firebaseIdbService.loadIdbNotifications();
          if (this.isAckRequired) {
            this.dismiss.emit();
            this.router.navigate([CREW_ROUTES.calendar]);
          }
        });
    });
  }

  showErrorToast(): void {
    this.toastService.showToast({
      backgroundColor: Colors.Afterburner,
      message: 'Failed to acknowledge notification',
    });
  }
}

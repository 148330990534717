import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'crew-ui-spinner',
    imports: [CommonModule],
    templateUrl: './crew-ui-spinner.component.html',
    styleUrls: ['./crew-ui-spinner.component.css']
})
export class CrewUISpinnerComponent {
  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  buildBar() {
    return {
      'sm-bar ': this.size === 'sm',
      'md-bar ': this.size === 'md',
      'lg-bar ': this.size === 'lg',
      absolute: true,
      shadow: true,
      'bg-steel': true,
    };
  }
}

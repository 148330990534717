import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
  CrewUIOverlayComponent,
  CrewUISwitchComponent,
  Selected,
  SizeTypes,
} from '../../../shared/components/crew-ui';
import { SabreDocumentKeywordDisplay } from '../../../shared/components/sabre-document/sabre-document.constant';
import { SabreDocumentService } from '../../../shared/components/sabre-document/sabre-document.service';
import { SettingsButtonComponent } from '../../../shared/components/settings-button/settings-button.component';
import { FlightLeg } from '../../../shared/services/azure/calendar.service.types';
import { formatDate } from '../../../shared/utils/date.utils';
import { CalendarCode } from '../calendar.constant';
import { CalendarService } from '../calendar.service';
import { ToggleButtonOption, ToggleEvents } from '../calendar.types';

@Component({
    selector: 'crew-web-calendar-menu',
    templateUrl: './calendar-menu.component.html',
    styleUrls: ['./calendar-menu.component.css'],
    imports: [
        CommonModule,
        CrewUIIconComponent,
        CrewUIOverlayComponent,
        CrewUISwitchComponent,
        SettingsButtonComponent,
    ]
})
export class CalendarMenuComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private calendarService: CalendarService,
    private sabreDocumentService: SabreDocumentService,
  ) {}

  isFilterVisible = false;

  toggleMore = false;

  isValidPreviousMonth = false;

  isValidNextMonth = false;

  size = SizeTypes.Small;

  colors = Colors;

  modifiedToggleOptions: boolean[] = [];

  get refreshTime(): string {
    return this.calendarService.lastRefreshTimeFormatted();
  }

  get currentDate(): Date {
    return this.calendarService.selectedDate();
  }

  get isDark(): boolean {
    return this.darkModeService.isDarkMode();
  }

  get selectedToggleEvents(): ToggleEvents[] {
    return this.calendarService.selectedToggleEvents();
  }

  get getToggleButtonOptions(): ToggleButtonOption[] {
    return this.calendarService.getToggleButtonOptions();
  }

  get isPreviousMonthValid(): boolean {
    return this.calendarService.isPreviousMonthValid();
  }

  get isNextMonthValid(): boolean {
    return this.calendarService.isNextMonthValid();
  }

  readonly sabreKeywordDisplay = SabreDocumentKeywordDisplay;

  applySwitchSettings(option: ToggleButtonOption): void {
    this.calendarService.onChangeToggleEvents(option);
  }

  stopEventPropagation(event: Event): void {
    event.stopPropagation();
  }

  onToggleMore(event: Event): void {
    event.stopPropagation();
    this.toggleMore = !this.toggleMore;
  }

  onClickList(event: Event, optionType: string): void {
    event.stopPropagation();
    if (optionType === 'filter') {
      this.isFilterVisible = !this.isFilterVisible;
    } else if (optionType === SabreDocumentKeywordDisplay.HI1) {
      this.sabreDocumentService.doSabreCall(optionType, {} as FlightLeg);
    } else if (optionType === 'refreshCalendar') {
      this.calendarService.loadCalendar();
    }
  }

  /**
   * On click previous month
   */
  onClickPreviousMonth(isValid: boolean): void {
    if (isValid) {
      this.calendarService.onChangeCalendarMonth(
        this.calendarService.monthIndex() - 1,
        CalendarCode.PREVIOUSMONTH,
      );
    }
  }

  /**
   * On click next month
   */
  onClickNextMonth(isValid: boolean): void {
    if (isValid) {
      this.calendarService.onChangeCalendarMonth(
        this.calendarService.monthIndex() + 1,
        CalendarCode.NEXTMONTH,
      );
    }
  }

  formatDate(date: Date): string {
    return formatDate(date.toString(), 'MMMM');
  }

  /**
   * On change Previous/Next month, change the button color
   * @param value - true/false
   * @returns- Colors
   */
  iconColor(value: boolean): Colors {
    let style = this.isDark
      ? this.colors.Troposphere
      : this.colors.Stratosphere;
    if (value) {
      style = this.isDark ? this.colors.Steel : this.colors.Nickel;
    }
    return style;
  }

  toggleDarkMode($event: Selected): void {
    this.darkModeService.toggle($event.selected);
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CrewUICardComponent } from '../crew-ui-card/crew-ui-card.component';
import { CrewUIIconComponent } from '../crew-ui-icon/crew-ui-icon.component';
import { CrewUIOverlayComponent } from '../crew-ui-overlay/crew-ui-overlay.component';

@Component({
    selector: 'crew-ui-modal',
    imports: [
        CommonModule,
        CrewUIOverlayComponent,
        CrewUICardComponent,
        CrewUIIconComponent,
    ],
    templateUrl: './crew-ui-modal.component.html',
    styleUrls: ['./crew-ui-modal.component.css']
})
export class CrewUIModalComponent {}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CREW_ROUTES } from '../../app-routing.module';
import { MessageService } from '../../routes/message/message.service';
import {
  Colors,
  CrewUIDarkModeService,
  CrewUIIconComponent,
} from '../../shared/components/crew-ui';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';

@Component({
    selector: 'crew-web-bottom-nav',
    templateUrl: './bottom-nav.component.html',
    styleUrls: ['./bottom-nav.component.css'],
    imports: [RouterModule, CommonModule, CrewUIIconComponent]
})
export class BottomNavComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private sabreDocumentService: SabreDocumentService,
    private messageService: MessageService,
  ) {}

  @Input()
  routerUrl = '';

  @Input()
  getCrewPath!: (args: string) => void;

  @Input()
  isActiveRoute!: (args: string) => boolean;

  routes = CREW_ROUTES;

  colors = Colors;

  get darkMode() {
    return this.darkModeService.isDarkMode();
  }

  get messageCount() {
    return this.messageService.lowAndMediumUnreadMessageCount();
  }

  get hi6Count() {
    return this.messageService.hi6Count();
  }

  buildIconText(value: boolean): string {
    let style = this.darkMode ? Colors.Troposphere : Colors.Stratosphere;
    if (value) {
      style = this.darkMode ? Colors.Aluminum : Colors.Steel;
    }
    return `text-${style}`;
  }

  closeHSS() {
    this.sabreDocumentService.clearSabreResponse();
  }
}

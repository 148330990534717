import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { CrewUIIconComponent } from '../../../shared/components/crew-ui';
import {
  DaysEvent,
  FlightDutyPeriod,
} from '../../../shared/services/azure/calendar.service.types';
import { Calendar } from '../../../shared/services/azure/calendar.service.types';
import { TimeService } from '../../../shared/services/time.service';
import { GridHeightOperationType } from '../../../shared/types';
import { formatDate, isDateInBetween } from '../../../shared/utils/date.utils';
import { CalendarService } from '../calendar.service';
import { ToggleEvents } from './../calendar.types';
@Component({
    selector: 'crew-web-calendar-info',
    templateUrl: './calendar-info.component.html',
    styleUrls: ['./calendar-info.component.css'],
    imports: [CommonModule, CrewUIIconComponent]
})
export class CalendarInfoComponent {
  get calendar(): Calendar[] | undefined {
    return this.calendarService.calendar();
  }

  get monthIndex(): number {
    return this.calendarService.monthIndex();
  }

  get daysInMonth(): Date[] {
    return this.calendarService.daysInMonth();
  }

  get selectedToggleEvents(): ToggleEvents[] {
    return this.calendarService.selectedToggleEvents();
  }

  get calendarSelectedDate(): Date {
    return this.calendarService.calendarSelectedDate();
  }

  readonly gridHeightOperationType = GridHeightOperationType;

  constructor(
    private timeService: TimeService,
    private calendarService: CalendarService,
  ) {}

  /**
   * On click calendar day get the sequence details for the day
   */
  onSelectDay(event: Event, day: Date): void {
    event.stopPropagation();
    this.calendarService.setSelectedEvents(day);
  }

  /**
   * validate the two dates
   * 'YYYY-MM-DD' = 'YYYY-MM-DD'
   */
  validateDate(date: string, day: string): boolean {
    return formatDate(date, 'YYYY-MM-DD') === formatDate(day, 'YYYY-MM-DD');
  }

  /**
   * Set the event title style
   * If it has the `sequenceActivity`.
   */
  setEventTitleStyle(event: DaysEvent): string {
    return event.sequenceActivity ? `text-right pr-1` : ``;
  }

  /**
   * Show event title only in the start day
   */
  showEventTitle(event: DaysEvent, day: Date): string {
    let title = '';
    if (this.validateDate(event.start, day.toString())) {
      title = event.sequenceActivity
        ? this.timeService.getTime(event.start)
        : event.title;
    }
    if (
      event.sequenceActivity &&
      day.getDay() === 0 &&
      !this.validateDate(event.start, day.toString())
    ) {
      title = '<';
    }
    return title;
  }

  /**
   * Show Event Duty Period Value
   * If the sequence continues after saturday `day.getDay() === 6` to display in next row
   * !-- Commented code is to display the numberOfLegs(legsPerDutyPeriod) in each sequence on end day Eg. 3(2-2-3)
   * !-- In figma it is visible, If it is not required we can remove.
   */
  showEventDutyPeriodValue(event: DaysEvent, day: Date): string {
    let title = '';
    /* if (
      event.sequenceActivity &&
      this.validateDate(event.end, day.toString()) &&
      formatDate(event.start, 'YYYY-MM-DD') !==
        formatDate(event.end, 'YYYY-MM-DD')
    ) {
      title = `${event.numberOfLegs}(${event.sequenceActivity.legsPerDutyPeriod})`;
    } */
    if (
      event.sequenceActivity &&
      day.getDay() === 6 &&
      formatDate(day, 'YYYY-MM-DD') < formatDate(event.end, 'YYYY-MM-DD')
    ) {
      title = `>`;
    }
    return title;
  }

  /**
   * Block the calendar dates with the color
   * @param event - calendar event record
   * @returns - background color and text color css class
   */
  setCalendarStyle(event: DaysEvent, day: Date): string {
    const style = this.calendarService.getEventColor(event, day);
    return `${style.bgColor} ${style.textColor}`;
  }

  /**
   * Set style for the event line compared with start, end and day
   */
  setEventLine(event: DaysEvent, day: Date): string {
    let style = ``;
    const eventStart = formatDate(event.start, 'YYYY-MM-DD');
    const eventEnd = formatDate(event.end, 'YYYY-MM-DD');
    const today = formatDate(day, 'YYYY-MM-DD');
    if (eventStart === today && eventEnd === today) {
      style = `rounded-sm`;
    }
    if (eventStart === today && eventEnd !== today) {
      style = `rounded-tl-sm rounded-bl-sm relative left-[1px]`;
    }
    if (eventEnd === today && eventStart !== today) {
      style = `rounded-tr-sm rounded-br-sm relative right-[1px]`;
    }
    if (eventStart !== today && eventEnd !== today) {
      style = `flex-auto mx-[-1px]`;
      if (day.getDay() === 6) {
        style = `flex-auto ml-[-1px] mr-0`;
      }
      if (day.getDay() === 0) {
        style = `flex-auto mr-[-1px] ml-0`;
      }
    }
    if (
      formatDate(day, 'YYYY-MM-DD') ===
      formatDate(this.calendarSelectedDate, 'YYYY-MM-DD')
    ) {
      style = `flex-auto mx-0`;
    }
    return style;
  }

  /**
   * Draw the event line compared with start, end and day
   */
  drawEventLine(event: DaysEvent, day: Date): string {
    const eventStart = formatDate(event.start, 'YYYY-MM-DD');
    const eventEnd = formatDate(event.end, 'YYYY-MM-DD');
    const eventDay = formatDate(day, 'YYYY-MM-DD');
    let style = ``;
    if (eventStart === eventDay && eventEnd === eventDay) {
      style = `pl-1 pr-1`;
    }
    if (eventStart === eventDay && eventEnd !== eventDay) {
      style = `pl-1 pr-0`;
    }
    if (eventEnd === eventDay && eventStart !== eventDay) {
      style = `pl-0 pr-1`;
    }
    return style;
  }

  /**
   * Set calendar day box style
   */
  showDayStyle(day: Date): string {
    let style = `dark:bg-black bg-cirrus dark:border-black border-cirrus border-t-2 border-solid`;
    if (this.isCurrentMonth(day)) {
      style = `dark:bg-charcoal bg-white dark:border-charcoal border-white border-t-2 border-solid`;
    }
    if (
      formatDate(day, 'YYYY-MM-DD') ===
      formatDate(this.calendarSelectedDate, 'YYYY-MM-DD')
    ) {
      style = `dark:border-troposphere border-stratosphere dark:bg-charcoal bg-ozone border-2 border-solid`;
    }
    return style;
  }

  /**
   * Display each calendar box style
   */
  showDayTextStyle(day: Date): string {
    let style = `font-normal text-xs dark:text-white text-carbon`;
    if (this.isToday(day)) {
      style = `w-full dark:bg-troposphere bg-stratosphere dark:text-carbon text-white rounded-xl font-bold text-[11px] leading-[14px]`;
    }
    if (
      formatDate(day, 'YYYY-MM-DD') ===
        formatDate(this.calendarSelectedDate, 'YYYY-MM-DD') &&
      !this.isToday(day)
    ) {
      style = `font-bold text-xs dark:text-white text-stratosphere`;
    }
    return style;
  }

  /**
   * Apply style if the day is today
   */
  isToday(date: Date): boolean {
    return (
      formatDate(date, 'YYYY-MM-DD') === formatDate(new Date(), 'YYYY-MM-DD')
    );
  }

  /**
   * Validate the active month is the current calendar month
   * If current month - bg-white
   * If previous/next month - bg-cirrus
   */
  isCurrentMonth(day: Date): boolean {
    return day.getMonth() === new Date().getMonth();
  }

  /**
   * Based on the @Input calendarData, @Input() monthIndex, i as index
   * Get the calendar daysEvents
   */
  getEventsForDay(i: number): DaysEvent[] {
    if (!this.calendar) return [];

    const hasEvents =
      this.calendar[this.monthIndex] &&
      this.calendar[this.monthIndex].days &&
      this.calendar[this.monthIndex].days[i] &&
      this.calendar[this.monthIndex].days[i].daysEvents.length > 0;

    if (hasEvents) {
      return this.calendar[this.monthIndex].days[i].daysEvents;
    } else return [];
  }

  /**
   * In the sequence last day display sequence end time
   * In the sequence except last day display `layOverAirport`
   */
  getLayOverAirport(
    fd: FlightDutyPeriod[],
    endDate: string,
    day: Date,
    beginsToday: boolean,
  ): string {
    let dutyPeriod = ``;
    if (beginsToday && fd[0].layOverAirport) {
      dutyPeriod = `${fd[0].layOverAirport}`;
    }
    if (!beginsToday && fd[fd.length - 1].layOverAirport) {
      dutyPeriod = `${fd[fd.length - 1].layOverAirport}`;
    }
    if (formatDate(endDate, 'YYYY-MM-DD') === formatDate(day, 'YYYY-MM-DD')) {
      dutyPeriod = this.timeService.getTime(endDate);
    }
    return dutyPeriod;
  }

  /**
   * Check the calendar day ==== event start day
   *  OR
   * Check the calendar day is in between event start/end date
   * @param event - calendar event record
   * @param day - present day
   * @returns - true / false
   */
  checkCurrentDay(event: DaysEvent, day: Date): boolean {
    return (
      this.validateDate(event.start, day.toString()) ||
      isDateInBetween(event.start, event.end, day)
    );
  }

  /**
   * If we have more than one sequence, show the number of legs
   * @param events
   * @returns
   */
  getNumberOfLegsHasMoreThanOneSequence(i: number): number {
    const events = this.getEventsForDay(i);
    let numberOfLegs = 0;
    if (events && events.length > 0) {
      events.filter((e: DaysEvent) => {
        numberOfLegs = numberOfLegs + e.numberOfLegs;
      });
    }
    return numberOfLegs;
  }

  /**
   * If we have more than one sequence, show the layOverStation
   * some times it has the value Eg. MIA-
   * @param events
   * @returns
   */
  getLayOverAirportHasMoreThanOneSequence(i: number, day: Date): string {
    let layOverStation = ``;
    let endDate = ``;
    const events = this.getEventsForDay(i);
    if (events && events.length > 0) {
      events.filter((e: DaysEvent) => {
        if (e && e.sequenceActivity && e.sequenceActivity.layoverStations) {
          layOverStation = e.sequenceActivity.layoverStations;
          layOverStation = layOverStation.split('-').join('');
        }
        if (formatDate(e.end, 'YYYY-MM-DD') === formatDate(day, 'YYYY-MM-DD')) {
          endDate = this.timeService.getTime(e.end);
        }
      });
    }
    return layOverStation ? layOverStation.trim() : endDate;
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FitTextDirective } from '../../directives/fit-text';
import { SafeHtmlPipe } from '../../pipes/safeHtml.pipe';
import { sanitizeText } from '../../utils/string.utils';

@Component({
    selector: 'crew-web-safe-html',
    templateUrl: './safe-html.component.html',
    styleUrls: ['./safe-html.component.css'],
    imports: [CommonModule, SafeHtmlPipe, FitTextDirective]
})
export class SafeHtmlComponent {
  @Input() content: string | undefined;

  sanitizeSabreResponse(data: string | undefined): string {
    let response = ``;
    if (data) {
      response = sanitizeText(data, false, false);
    }
    return response;
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'crew-ui-overlay',
    imports: [CommonModule],
    templateUrl: './crew-ui-overlay.component.html',
    styleUrls: ['./crew-ui-overlay.component.css']
})
export class CrewUIOverlayComponent {
  @Input()
  isHidden = false;
}
